<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("studentGroups.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="newGroup()">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group id="input-group-3" label-for="input-3">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        id="input-3"
                        v-model="filter.educationYear"
                        :options="educationYears"
                        required
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group id="input-group-2" label-for="input-2">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        id="input-2"
                        v-model="filter.level"
                        :options="levels"
                        required
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary">{{
                  $t("common.filter")
                }}
              </b-button>
            </b-form>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("studentGroups.list.name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("studentGroups.list.level") }}
                  </th>
                  <th class="text-left">
                    {{ $t("studentGroups.list.year") }}
                  </th>
                  <th class="text-left">
                    {{ $t("studentGroups.list.shift") }}
                  </th>
                  <th class="text-left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in groups" :key="item.id">
                  <td>{{ item.groupName }}</td>
                  <td>{{ item.level }}</td>
                  <td>{{ item.educationYearString }}</td>
                  <td>{{ shiftVal(item.shift) }}</td>
                  <td>
                    <b-button
                        type="submit"
                        @click="editGroup(item)"
                        variant="primary"
                    >
                      {{ $t("common.edit") }}
                    </b-button>
                    <b-button @click="openStudents(item)" variant="primary">
                      {{ $t("studentsList.title") }}
                    </b-button>
                    <b-button
                        @click="openGroupSubjects(item)"
                        variant="primary"
                    >
                      {{ $t("groupSubjects.title") }}
                    </b-button>
                    <b-button @click="openTimeTable(item)" variant="primary">
                      {{ $t("timeTable.title") }}
                    </b-button>
                    <b-button @click="openGradeBook(item)" variant="primary">
                      {{ $t("gradeBook.title") }}
                    </b-button>
                    <b-button @click="softDeleteGroup(item)" variant="primary">
                      Удалить мягко
                    </b-button>
                    <b-button @click="openGroupInfo(item)" variant="primary">
                      Детали
                    </b-button>
                    <b-button @click="deleteGroup(item)" variant="primary">
                      Удалить
                    </b-button>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div>
              <b-modal id="modal-1" ref="groups-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.name") }}
                    </template>
                    <b-form-input
                        id="input-1"
                        v-model="form.groupName"
                        type="email"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" label-for="input-2">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        id="input-2"
                        v-model="form.level"
                        :options="levels"
                        required
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group id="input-group-3" label-for="input-3">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        id="input-3"
                        v-model="form.educationYear"
                        :options="educationYears"
                        required
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.shift") }}
                    </template>
                    <b-form-select
                        id="input-4"
                        v-model="form.shift"
                        :options="shifts"
                        required
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      Расписание звонков
                    </template>
                    <b-form-select
                        id="input-4"
                        v-model="form.callScheduleId"
                        :options="callSchedules"
                        required
                    ></b-form-select>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "studentGroups",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter:{
        educationYear: DictionariesService.currentYear(),
        level: 1
      },
      schoolId: null,
      groups: null,
      dialog: true,
      form: {
        groupName: "",
        level: "",
        educationYear: "",
        shift: "",
        callScheduleId: 0
      },
      show: true,
      levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      educationYears: DictionariesService.educationYearOptions(),
      shifts: [{text: "Первая", value: 1}, {text: "Вторая", value: 2}, { text: "Третья", value: 3 }],
      callSchedules: [],
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.schoolId = this.$route.params.id;

      ApiService.postSecured("studentGroups/save", this.form)
          .then(function () {
            $this.$refs["groups-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["groups-modal"].hide();
    },
    reloadData() {
      this.filter.schoolId = this.$route.params.id;
      let $this = this;
      ApiService.postSecured("studentGroups/listSchool", this.filter).then(({data}) => {
        $this.groups = data.list;
      }).catch(({response}) => {
        console.log(response);
      });
      
      
      ApiService.querySecured("dictionaries/callSchedules")
          .then(function (data) {
            $this.callSchedules = data.dictionary;
            $this.callSchedules.unshift({value: 0, text: ""});
          }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    newGroup() {
      this.form = {};
      this.$refs["groups-modal"].show();
    },
    editGroup(group) {
      this.form = group;
      this.$refs["groups-modal"].show();
    },
    openStudents(group) {
      this.$router.push({name: "students", params: {id: group.id}});
    },
    openGroupSubjects(group) {
      this.$router.push({name: "groupSubjects", params: {id: group.id}});
    },
    openTimeTable(group) {
      this.$router.push({name: "timeTable", params: {id: group.id}});
    },
    openGradeBook(group) {
      this.$router.push({name: "groupGradeBook", params: {groupId: group.id}});
    },  
    openGroupInfo(group) {
      this.$router.push({name: "groupInfo", params: {groupId: group.id}});
    },
    deleteGroup(group) {
      if(confirm("Удалить? Удаляйте только новые классы!!!!!")){
        let $this = this;
        ApiService.postSecured("admin/deleteGroup", {groupId:group.id})
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response);
              $this.reloadData();
            });
      }      
    },  
    softDeleteGroup(group) {
      if(confirm("Удалить мягко?")){
        let $this = this;
        ApiService.postSecured("admin/softDeleteGroup", {groupId:group.id})
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response);
              $this.reloadData();
            });
      }      
    },
    shiftVal(shift) {
      let shiftVal = '';
      switch (shift) {
        case 1:
          shiftVal = 'Первая';
          break;
        case 2:
          shiftVal = 'Вторая';
          break;
        case 3:
          shiftVal = 'Третья';
          break;
      }

      return shiftVal;
    }
  }
};
</script>
